<template>
  <div>
    <Header :seet="number" />
      <el-row type="flex" class="account—settings">
        <el-col :span="3" style="width:15.3%;"></el-col>
        <el-col :span="17">
          <div class="settings_box">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>账号中心</el-breadcrumb-item>
              <el-breadcrumb-item>收货地址</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="display:flex;">
              <div class="box_left">
                <LeftHurdle/>
              </div>
              <div class="box_rights">
                <div class="box_right_top">收货地址</div>
                <div class="box_right_cent">
                    <div class="address_box">
                        <div class="adress_box_btn flex-start">
                            <div class="newly_btn" @click="newlyAdd()">新增地址</div>
                            <div class="out_btn" @click="uploadflie = true">批量导入</div>
                            <div class="out_btn" @click="derive()">导出地址模板</div>
                            <div>已有{{total}}条地址</div>
                        </div>
                        <div class="adress_box_cent flex-start">
                            <el-input class="address-input" v-model="address"></el-input>
                            <div class="input-btn" @click="inquire()">查询</div>
                            <div class="input-Btn" @click="empty()">清空</div>
                        </div>
                        <div class="address_cent_box flex-start" v-for="(item,index) in addList" :key="index">
                            <div class="address_left">
                                <div>{{item.receiveName}}</div>
                                <div style="margin:5px 0;">{{item.receivePhone}}</div>
                                <div>{{item.province}}{{item.city}}{{item.area}}{{item.address}}</div>
                                
                            </div>
                            <div class="address-right">
                                <div @click="EditAddress(item)">编辑</div>
                                <div @click="dialog(item.id)" style="margin:5px 0">删除</div>
                                <div v-show="item.isDefault===1" class="defaultAdd">默认地址</div>
                                <div v-show="item.isDefault===0" @click="defaultSet(item.id)">设置默认</div>
                            </div>
                            <el-dialog
                              :visible.sync="dialogVisible"
                              width="30%">
                              <span>确认删除地址</span>
                              <span></span>
                              <span slot="footer" class="dialog-footer">
                                <el-button @click="dialogVisible = false">取 消</el-button>
                                <el-button type="primary" @click="DeleteAddress(id)">确 定</el-button>
                              </span>
                            </el-dialog>
                        </div>
                        <div v-show="addList.length==0">
                          <div style="height:500px;line-height:500px;text-align:center;">暂无地址,去新增地址</div>
                        </div>
                    </div>
                </div>
                <div class="box_right_bom">
                    <div class="flex-end" v-show="addList.length!=0">
                      <el-row type="flex" class="row-bg" justify="space-between">
                        <!-- <el-col :span="12">
                          <div class="selgoodscouts">共{{total}}个商品，已选择{{this.checkBoxData.length}}个商品<span v-show="this.checkBoxData.length >19">(最多选择20个商品，请删除后再添加其余商品)</span></div>
                        </el-col> -->
                        <el-col :span="12" style="text-align: right;">
                          <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page.sync="currentPage"
                            :page-size="20"
                            layout="prev, pager, next, jumper"
                            :total="total">
                          </el-pagination>
                        </el-col>
                      </el-row>
                    </div>
                </div>
              </div>
              <el-dialog title="导入地址" width="40%" :visible.sync="uploadflie" :modal-append-to-body="false" class="tbdialog undraggablebox">
                <div style="margin-left: 50px;">
                  <span>上传数据：</span>
                  <el-upload style="display: inline"
                            class="upload-demo"
                            action="#"
                            :limit="1"
                            :before-upload="beforeUpload"
                            :on-remove="handleRemove"
                            :http-request="handlePictureSuccess">
                    <el-button type="text">选择文件</el-button>
                  </el-upload>
                </div>
                <span slot="footer" class="dialog-footer">
                  <el-button type="primary" :disabled="uploadfile===undefined" :loading="importBtn" @click="handleUpload">确 定</el-button>
                </span>
              </el-dialog>
            </div>
          </div>
        </el-col>
        <el-col :span="3" style="width:15.5%;"></el-col>
    </el-row>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Layout/header.vue'
import Footer from '@/components/Layout/footer.vue'
import LeftHurdle from '@/components/Layout/leftHurdle.vue'
import { goodsAddress,Setdefault } from "@/api/order/order";
import { DeleteAddress,channelAddress } from "@/api/personage/personage";
export default {
  components: { Header,Footer,LeftHurdle },
  data () {
      return{
        number:'4',
        pageNum:1,
        pageSize:10,
        addList:[],
        dialogVisible:false,
        id:'',
        uploadflie:false,
        //上传的文件
        uploadfile: undefined,
        importBtn:false,
        total: 1,
        currentPage: 1,
        address:''
      }
  },
  created(){
    this.getAddress("",1)
  },
  methods:{
    getAddress(addresss,val){
      let data={
        addressInfo:addresss,
        myPage:{
          pageNum:val,
          pageSize:this.pageSize
        },
      }
      goodsAddress(data).then((data) => {
        if(data&&data.code=='00000'){
          this.addList=data.data.records
          this.total= data.data.total
        }
      });
    },
    newlyAdd(){
        this.$router.push({ name: 'NewlyAddress' })
    },
    EditAddress(item){
      this.$router.push({ name: 'EditAddress',query:{data:JSON.stringify(item)} })
    },
    dialog(id){
      this.id=id
      this.dialogVisible=true
    },
    defaultSet(id){
      let data ={
        id:id
      }
      Setdefault(data).then((data)=>{
        if(data.code=='00000'){
          this.getAddress("",1)
        }
      })
    },
    DeleteAddress(id){
      let data={
        'addressId':id
      }
      DeleteAddress(data).then((data) => {
        if(data&&data.code=="00000"){
          this.dialogVisible=false
          this.getAddress('',1)
        }
      });
    },
    //导出地址模板
    derive(){
      let a = document.createElement("a"); //创建一个<a></a>标签
      a.href = "/file/ImportTemplate.xls"; // 给a标签的href属性值加上地址，注意，这里是绝对路径，不用加 点.
      a.download = "导入地址模板.xls"; //设置下载文件文件名，这里加上.xlsx指定文件类型，pdf文件就指定.fpd即可
      a.style.display = "none"; // 障眼法藏起来a标签
      document.body.appendChild(a); // 将a标签追加到文档对象中
      a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
      a.remove(); // 一次性的，用完就删除a标签
    },
    handleSizeChange(val) {
      this.getAddress(val);
    },
    handleCurrentChange(val) {
      this.getAddress(val);
    },
    //上传文件
    handleUpload(){
      this.importBtn = true;
      // 文件重命名
      let Newfile = new File([this.uploadfile], 'uploadTemplate'+this.uploadfile.name.substr(this.uploadfile.name.lastIndexOf(".xls")), {
          type: this.uploadfile.type
      });
      let formData = new FormData();
      formData.append('file', Newfile)
      console.log(Newfile);
      channelAddress(formData).then((data)=>{
        this.importBtn = false;
        if(data&&data.code =='00000'){
          this.uploadflie=false
          this.$message({
            message: "导入成功",
            type: "success",
          });
          this.getAddress("",1)
        }
      })
    },
    // 上传文件前 先将文件存起来
    beforeUpload(file){
      this.uploadfile = file
    },
    //删除文件
    handleRemove () {
      this.uploadfile = undefined;
    },
    handlePictureSuccess() {},
    //查询
    inquire(){
      this.getAddress(this.address,1)
    },
    empty(){
      this.address=''
      this.getAddress("",1)
      
    }
  }

}
</script>

<style lang="scss">
.settings_box{
  margin: 30px 0;
  .el-breadcrumb{
    font-size: 18px;
    padding-bottom: 20px;
  }
  .box_left{
    margin-right: 20px;
  }
  .box_rights{
    width: 87%;
    // height: 660px;
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    .box_right_top{
      height: 40px;
      line-height: 40px;
      padding-left: 40px;
      background: #d7d7d7;
      border-bottom: 1px solid #d7d7d7;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-weight: bold;
    }
    .box_right_cent{
        // height: 578px;
    }
    .address_box{
        padding: 30px 20px;
        .adress_box_btn{
            font-size: 14px;
            color: #999;
        }
        .newly_btn{
            height: 28px;
            line-height: 28px;
            width: 90px;
            text-align: center;
            color: #fff;
            background: #CD9F49;
            border-radius: 5px;
            font-size: 12px;
        }
        .out_btn{
            height: 28px;
            line-height: 28px;
            width: 90px;
            text-align: center;
            border-radius: 5px;
            font-size: 12px;
            margin: 0 15px;
            color: #333;
            border: 1px solid #333;
        }
        .adress_box_cent{
            margin: 20px 0;
            .address-input{
                width: 25%;
            }
            .input-btn{
                height: 28px;
                line-height: 28px;
                width: 55px;
                text-align: center;
                color: #fff;
                background: #CD9F49;
                border-radius: 5px;
                font-size: 12px;
                margin: 0 15px;
            }
            .input-Btn{
                height: 28px;
                line-height: 28px;
                width: 55px;
                text-align: center;
                border-radius: 5px;
                font-size: 12px;
                color: #333;
                border: 1px solid #333;
            }
        }
        .address_cent_box{
            width: 100%;
            border: 1px solid #d7d7d7;
            margin-bottom: 10px;
            border-radius: 10px;
            
            .address_left{
                width: 80%;
                padding: 30px 30px;
                font-size: 14px;
            }
            .address-right{
                width: 20%;
                border-left: 1px solid #d7d7d7;
                padding: 30px;
                text-align: center;
                font-size: 12px;
                color: #CD9F49;
                .defaultAdd{
                  height: 28px;
                  line-height: 28px;
                  width: 90px;
                  text-align: center;
                  color: #fff;
                  background: #CD9F49;
                  border-radius: 5px;
                  font-size: 12px;
                  margin: 0 auto;
                }
            }
        }
    }
    .box_right_bom{
        height: 40px;
        line-height: 40px;
        padding-left: 40px;
        background: #d7d7d7;
        border-bottom: 1px solid #d7d7d7;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
  }
}
</style>